div#menu {
  position: fixed;
  background-color: rgba(255,255,255,0.95);
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0px;
}

.terminal-container {
  margin-top: 56px;
  width: 800px;
  height: 600px;
  position: absolute;
  left: 0px;
  top: -56px;
  z-index: 1000;
  background: black;
}

.carousel-item.active {
  /* width: 960px; */
  height: 572px;
}

img {
  display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.footer-fluid-container{
  background-color: darkgray;
}