#upload-picture-label{
    cursor: pointer;
}

#upload-picture-label input{
    opacity: 0;
    width: 0px;
    height: 0px;
}

div.show-image {
    position: relative;
    /*float:left;*/
    margin:5px;
}
div.show-image:hover img{
    opacity:0.5;
}
div.show-image:hover input {
    display: block;
}
div.show-image input {
    position:absolute;
    display:none;
}
div.show-image input.update {
    top:0;
    left:0;
}
div.show-image input.delete {
    top:0;
    left:0px;
}