
.paral-fiche-contact h4{
    color:rgba(255, 255, 255, 0.8)!important;
}

.paral-fiche-contact span,
.paral-fiche-contact a{
    color:springgreen!important;
}

svg{
    margin-right: 10px;
    background-color: transparent;
    vertical-align: top;
}

h4 {
    /* font-family: "Roboto"; */
    /* font-family: "Lato"; */
    font-family: "Arapey";
    font-size: xx-large;
}