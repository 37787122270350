.dropdown-menu{
    max-height: 300px;
    overflow: auto;
}

#dropdown_input_filter{
    width: -webkit-fill-available;
    margin: 3px;
    color: black!important;
}

.site_header,
.pager,
.hidden,
.site_footer,
.breadcrumb,
.toolbar,
.egw_refcode,
ul.breadcrumb,
.footnote
{
    display: none;
}

.egwlink{
    text-decoration: none;
    color:#1D242C
}